export function useString() {
  function capitalize(string: string) {
    return string
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  }

  function cleanPhoneNumber(phoneNumber: string) {
    const cleanedPhoneNumber = phoneNumber.replace(/[^\d+]/g, '')

    return cleanedPhoneNumber
      .startsWith('0049')
      ? `+${cleanedPhoneNumber.substring(2)}`
      : (cleanedPhoneNumber.startsWith('0')
          ? `+49${cleanedPhoneNumber.substring(1)}`
          : (cleanedPhoneNumber.startsWith('+49')
              ? cleanedPhoneNumber
              : `+49${cleanedPhoneNumber}`))
  }

  function getInitials(fullName: string) {
    const names = fullName.split(' ')
    let initials = names[0].substring(0, 1).toUpperCase()

    if (names.length > 1)
      initials += names[names.length - 1].substring(0, 1).toUpperCase()

    return initials
  }

  return {
    capitalize,
    cleanPhoneNumber,
    getInitials,
  }
}
